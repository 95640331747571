import React, {useEffect, useState} from "react";
import Carousel from "react-material-ui-carousel";
import {Box, Grid, Paper} from "@mui/material";
import {Parallax} from "react-parallax";
import programming from "../Images/Experience/experienceProgramming.jpeg";
import paperWork from "../Images/Experience/experiencePaperWork.jpeg";
import letter from "../Images/Experience/experienceLetter.jpeg";
import teacher from "../Images/Experience/experienceTeacher.jpeg";
import CustomTypography from "../Components/CustomTypography";

const ExperienceItem = ({experience}) => {
    const descriptionItems = experience.description.split("\n");

    return (
        <Paper
            elevation={3}
            sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "center",
                backgroundColor: "rgba(18, 18, 18, 0.96)",
                color: "white",
                borderBottomLeftRadius: 12,
                borderBottomRightRadius: 12,
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
                paddingBottom: "10px",
            }}
        >
            <CustomTypography
                variant="h2"
                sx={{
                    paddingTop: "10px",
                    paddingLeft: "30px",
                    paddingRight: "30px",
                    color: "#F7DC6F",
                }}
                gutterBottom
            >
                {experience.title}
            </CustomTypography>
            <CustomTypography
                variant="h3"
                sx={{
                    paddingLeft: "30px",
                    paddingRight: "30px",
                    textAlign: "center",
                    color: "#FADBD8",
                }}
                gutterBottom
            >
                {experience.workWith}
            </CustomTypography>
            <CustomTypography
                variant="h4"
                sx={{
                    width: "100%",
                    paddingLeft: "30px",
                    paddingRight: "30px",
                    paddingBottom: "10px",
                    textAlign: "center",
                    color: "#A9CCE3",
                }}
            >
                {experience.date}
            </CustomTypography>

            <Box component="ul" sx={{width: "100%", paddingLeft: "45px", listStyleType: "disc"}}>
                {descriptionItems.map((item, index) => (
                    <CustomTypography
                        key={index}
                        variant="h4"
                        component="li"
                        sx={{
                            textAlign: "left",
                            paddingBottom: "5px",
                            whiteSpace: "pre-line",
                        }}
                    >
                        {item}
                    </CustomTypography>
                ))}
            </Box>
        </Paper>
    );
};

export const Experience = () => {
    const [parallaxHeight, setParallaxHeight] = useState("60vh");

    // Funktion zur Berechnung der Höhe basierend auf der Fensterbreite
    const updateParallaxHeight = () => {
        if (window.innerWidth < 600) {
            setParallaxHeight("40vh");
        } else {
            setParallaxHeight("60vh");
        }
    };

    useEffect(() => {
        // Beim ersten Rendern die Höhe setzen
        updateParallaxHeight();

        // Event Listener für Größenänderung hinzufügen
        window.addEventListener("resize", updateParallaxHeight);

        // Event Listener entfernen, wenn die Komponente unmountet wird
        return () => {
            window.removeEventListener("resize", updateParallaxHeight);
        };
    }, []); // Leeres Array, damit es nur einmal ausgeführt wird

    const experiences = [
        {
            title: "React Developer (Remote)",
            workWith: "GALAXY XY 2016",
            date: "08.2024 - Present",
            description:
                "Working with GitLab, including Merge Requests, commits and version control\n Familiar with ticketing systems for task and project management\n Designing and managing database schemas\n Programming with React",
            wallpaper: programming,
        },
        {
            title: "Project Work (Remote)",
            workWith: "Department of Applied Informatics, University of Macedonia",
            date: "10.2023 - Present",
            description:
                "Further development of the prototype of my bachelor thesis as part of the ongoing research work in the MSN laboratory with the aim of publication",
            wallpaper: paperWork,
        },
        {
            title: "Civil Service",
            workWith: "Hellenic Post (ELTA)",
            date: "09.2018 - 09.2019",
            description:
                "Logging and sorting of incoming packages\n Shipping of packages and letters",
            wallpaper: letter,
        },
        {
            title: "Clerk and German tutor",
            workWith: "Samurai Security Hellas",
            date: "09.2017 - 09.2018",
            description:
                "Selection of service personnel for Fraport AG\n German tutor for service personnel\n Coordinator of all German tutors at Samurai Security Hellas",
            wallpaper: teacher,
        },
    ];

    return (
        <section id="experience">
            <CustomTypography
                variant="h1"
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    paddingBottom: "10px",
                    textAlign: "center",
                }}
            >
                Experience
            </CustomTypography>

            <Carousel
                indicators={true}
                navButtonsAlwaysVisible={true}
                animation="slide"
                duration={600}
                fullHeightHover={false}
                autoPlay={false}
                interval={3500}
                stopAutoPlayOnHover={true}
                navButtonsProps={{
                    style: {
                        backgroundColor: "rgba(0, 0, 0, 0.4)",
                        borderRadius: 12,
                        width: 50,
                        height: 50,
                        top: "calc(50% - 25px)", // Buttons mittig im Bild
                    },
                }}
                indicatorContainerProps={{
                    style: {
                        marginTop: "10px", // Abstand zu den Buttons
                    },
                }}
                activeIndicatorIconButtonProps={{
                    style: {
                        color: "white",
                    },
                }}
                indicatorIconButtonProps={{
                    style: {
                        color: "gray",
                    },
                }}
                sx={{
                    paddingBottom: "100px",
                    paddingTop: "0px",
                    position: "relative",
                    "& .MuiMobileStepper-dots": {
                        bottom: "10px", // Indikatoren nach unten verschieben
                    },
                    "@media (max-width: 600px)": {
                        "& .MuiMobileStepper-dots": {
                            position: "relative",
                            bottom: "-30px", // Bei kleineren Bildschirmen die Indikatoren direkt unter das Bild setzen
                        },
                    },
                }}
            >
                {experiences.map((experience, index) => (
                    <Parallax
                        key={index}
                        bgImage={experience.wallpaper}
                        strength={500}
                        bgImageStyle={{
                            objectFit: "cover",
                            height: "200%",
                            width: "100%",
                        }}
                        style={{
                            height: parallaxHeight, // Dynamische Höhe basierend auf Bildschirmgröße
                            overflow: "hidden",
                        }}
                    >
                        <Grid
                            container
                            justifyContent="center"
                            alignItems="center"
                            sx={{
                                height: "100%",
                                overflow: "hidden",
                            }}
                        >
                            <Grid item xs={1} md={2}></Grid>
                            <Grid
                                item
                                xs={10}
                                md={8}
                                sx={{
                                    overflow: "hidden",
                                }}
                            >
                                <ExperienceItem experience={experience}/>
                            </Grid>
                            <Grid item xs={1} md={2}></Grid>
                        </Grid>
                    </Parallax>
                ))}
            </Carousel>
        </section>
    );
};
