import React from 'react';
import {Box, Grid} from '@mui/material';
import CustomTypography from "../../../Components/CustomTypography";
import {styled} from '@mui/system';
import {useNavigate} from 'react-router-dom';

const HoverBox = styled(Box)(({theme}) => ({
    position: 'relative',
    borderRadius: '12px',
    overflow: 'hidden',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    border: '1px solid #A9CCE3',
    '&:hover .hover-content': {
        opacity: 1, // Zeigt den Text bei Hover an
    }
}));

const HoverContent = styled(Box)(({theme}) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Halbtransparente schwarze Überlagerung
    opacity: 0, // Standardmäßig unsichtbar
    transition: 'opacity 0.3s ease', // Sanfte Einblendung bei Hover
    textAlign: 'center', // Zentriert den Text auch bei Umbrüchen
}));

const ProjectClassesContainer = ({objectArray}) => {
    const navigate = useNavigate();
    const handleClick = (url) => {
        if (url) {
            navigate(url);
        }
    };

    return (
        <Grid
            container
            spacing={{xs: 2, sm: 2, md: 3}}
            justifyContent="flex-start"
        >
            {objectArray.map((object, index) => (
                <Grid
                    item
                    xs={12}  // 1 Spalte bei xs-Bildschirmen
                    sm={6}   // 2 Spalten bei sm-Bildschirmen
                    md={6}   // 2 Spalten bei md-Bildschirmen
                    lg={4}   // 3 Spalten bei lg-Bildschirmen
                    key={index}
                >
                    <Box
                        onClick={() => handleClick(`/projects/${object.title.toLowerCase()}`)}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            gap: {
                                xs: '10px',
                                sm: '5px',
                                md: '5px',
                            },
                            cursor: 'pointer',
                        }}
                    >
                        <CustomTypography
                            variant="h4"
                            sx={{
                                textAlign: 'center',
                                height: 'auto',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                overFlow: 'hidden',
                                color: '#A9CCE3',
                            }}
                        >
                            {object.title}
                        </CustomTypography>

                        <HoverBox
                            sx={{
                                width: {
                                    xs: 'calc(85% - 10px)',  // Volle Breite bei kleinen Bildschirmen
                                    sm: 'calc(85% - 10px)',  // Zwei Spalten, mit Platz für den Abstand
                                    md: 'calc(90% - 10px)',  // Drei Spalten
                                    lg: 'calc(100% - 10px)', // Wieder zwei Spalten bei größeren Bildschirmen
                                },
                                height: {
                                    xs: '100px',
                                    sm: '130px',
                                    md: '140px',
                                    lg: '150px',
                                },
                                backgroundImage: object.image ? `url(${object.image})` : 'url(${})',
                            }}
                        >
                            {/* HoverText wird als CustomTypography hier hinzugefügt */}
                            <HoverContent className="hover-content">
                                <CustomTypography
                                    variant="h5"
                                    sx={{
                                        color: 'white', // Textfarbe
                                        fontWeight: 'bold', // Fettschrift
                                        padding: '10px', // Abstand um den Text
                                        whiteSpace: 'pre-wrap', // Textumbruch
                                    }}
                                >
                                    {object.description}
                                </CustomTypography>
                            </HoverContent>
                        </HoverBox>
                    </Box>
                </Grid>
            ))}
        </Grid>
    );
};

export default ProjectClassesContainer;
