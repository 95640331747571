import react from "../../../Images/Projects/projectClasses/react.webp";
import python from "../../../Images/Projects/projectClasses/python.png";
import java from "../../../Images/Projects/projectClasses/java.png";
import reactNative from "../../../Images/Projects/projectClasses/reactNative.png";
import itSecurity from "../../../Images/Projects/projectClasses/itSecurity.webp";
import solidity from "../../../Images/Projects/projectClasses/solidity.webp";

const projectClasses = [
    {
        title: 'React',
        image: react,
        description: 'Browse through my React projects.',
        category: 'reactArray'
    },
    {
        title: 'Python',
        image: python,
        description: 'Browse through my Python projects.',
        category: 'pythonArray'
    },
    {
        title: 'Java',
        image: java,
        description: 'Here’s a collection of my projects created with Java.',
        category: 'javaArray'
    },
    {
        title: 'React Native',
        image: reactNative,
        description: 'Check out the applications I’ve developed using React Native.',
        category: 'reactNativeArray'
    },
    {
        title: 'IT Security',
        image: itSecurity,
        description: 'Take a look at my IT Security-related projects.',
        category: 'itSecurityArray'
    },
    {
        title: 'Solidity',
        image: solidity,
        description: 'Take a look at my Solidity projects.',
        category: 'solidityArray'
    },
];

export default projectClasses;
