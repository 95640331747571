import React from "react";

const Privatepolicy = () => (
  <section className="ppolicy">
    <div>
      <h1>Privacy Policy</h1>
      <p>
        At <a href="https://www.gavouchidis.com">www.gavouchidis.com</a>,
        accessible from{" "}
        <a href="https://www.gavouchidis.com">www.gavouchidis.com</a>, one of
        our main priorities is the privacy of our visitors. This Privacy Policy
        document contains types of information that are collected and recorded
        by www.gavouchidis.com and how we use it.
      </p>

      <ul>
        <li>
          Log Files:{" "}
          <a href="https://www.gavouchidis.com">www.gavouchidis.com</a> does not
          use log files.
        </li>
        <li>
          Cookies and Web Beacons:{" "}
          <a href="https://www.gavouchidis.com">www.gavouchidis.com</a> does not
          use cookies.
        </li>
        <li>
          Privacy Policies of Third-Party Websites: Our Privacy Policy does not
          apply to other advertisers or websites. For this reason, we advise you
          to consult the respective Privacy Policies of third-party websites for
          more detailed information.
        </li>
      </ul>
    </div>
  </section>
);

export default Privatepolicy;
