import React from "react";

const Agb = () => (
  <section className="agb">
    <div>
      <h1>Terms and Conditions</h1>
      <p>Welcome to my portfolio website!</p>
      <p>
        These terms and conditions outline the rules and regulations for the use
        of <a href="https://www.gavouchidis.com">www.gavouchidis.com</a>'s
        Website. By accessing this website, we assume you accept these terms and
        conditions. Do not continue to use{" "}
        <a href="https://www.gavouchidis.com">www.gavouchidis.com</a> if you do
        not agree to all of the terms and conditions stated on this page.
      </p>
      <ul>
        <li>
          Content: All content on this website is owned by Stefanos Gavouchidis
          and may not be reproduced without our prior written consent.
        </li>
        <li>
          External Links: Our website may contain links to external websites
          that are not provided or maintained by or in any way affiliated with{" "}
          <a href="https://www.gavouchidis.com">www.gavouchidis.com</a>. Please
          note that we do not guarantee the accuracy, relevance, timeliness, or
          completeness of any information on these external websites.
        </li>
      </ul>
    </div>
  </section>
);

export default Agb;
