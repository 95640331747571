import React, {useEffect, useState} from "react";
import {Container, Nav, Navbar} from "react-bootstrap";

import CustomTypography from "./CustomTypography";

export const NavBar = () => {
    const [activeLink, setActiveLink] = useState("home");
    const [scrolled, setScrolled] = useState(false);
    const [navExpanded, setNavExpanded] = useState(false);
    const [isTogglerOpen, setIsTogglerOpen] = useState(false);

    useEffect(() => {
        const onScroll = () => {
            if (window.scrollY > 0) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        };
        window.addEventListener("scroll", onScroll);
        return () => window.removeEventListener("scroll", onScroll);
    }, []);

    const onUpdateActiveLink = (value) => {
        setActiveLink(value);
        //Schliesst den Toggler sobald man auf eine Section klickt
        setNavExpanded(false);
        setIsTogglerOpen(false);
    };

    const onToggleNavbar = () => {
        // updated function
        setIsTogglerOpen(!isTogglerOpen);
        setNavExpanded(!isTogglerOpen);
    };

    return (
        <Navbar
            expand="md"
            expanded={navExpanded}
            onToggle={onToggleNavbar}
            className={`${scrolled ? "scrolled" : ""} ${
                navExpanded ? "navbar-toggled" : ""
            }`}
        >
            <Container className="navBarContainer">
                <Navbar.Toggle aria-controls="basic-navbar-nav" className="toggleBar">
          <span
              className={`navbar-toggler-icon ${
                  isTogglerOpen ? "toggler-open" : ""
              }`}
          />
                </Navbar.Toggle>{" "}
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mx-auto">
                        <Nav.Link
                            href="/#home"
                            className={
                                activeLink === "home" ? "active navbar-link" : "navbar-link"
                            }
                            onClick={() => onUpdateActiveLink("home")}
                        >
                            <CustomTypography variant={"h4"} sx={{
                                fontSize: {xs: '2rem'}
                            }}>
                                Home
                            </CustomTypography>
                        </Nav.Link>

                        <Nav.Link
                            href="/#credentials"
                            className={
                                activeLink === "credentials"
                                    ? "active navbar-link"
                                    : "navbar-link"
                            }
                            onClick={() => onUpdateActiveLink("credentials")}
                        >
                            <CustomTypography variant={"h4"} sx={{
                                fontSize: {xs: '2rem'}
                            }}>
                                Credentials
                            </CustomTypography>

                        </Nav.Link>

                        <Nav.Link
                            href="/#skills"
                            className={
                                activeLink === "skills" ? "active navbar-link" : "navbar-link"
                            }
                            onClick={() => onUpdateActiveLink("skills")}
                        >
                            <CustomTypography variant={"h4"} sx={{
                                fontSize: {xs: '2rem'}
                            }}>
                                Skills
                            </CustomTypography>

                        </Nav.Link>

                        <Nav.Link
                            href="/#experience"
                            className={
                                activeLink === "experience"
                                    ? "active navbar-link"
                                    : "navbar-link"
                            }
                            onClick={() => onUpdateActiveLink("experience")}
                        >
                            <CustomTypography variant={"h4"} sx={{
                                fontSize: {xs: '2rem'}
                            }}>
                                Experience
                            </CustomTypography>

                        </Nav.Link>

                        <Nav.Link
                            href="/#project"
                            className={
                                activeLink === "projects" ? "active navbar-link" : "navbar-link"
                            }
                            onClick={() => onUpdateActiveLink("projects")}
                        >
                            <CustomTypography variant={"h4"} sx={{
                                fontSize: {xs: '2rem'}
                            }}>
                                Projects
                            </CustomTypography>

                        </Nav.Link>

                        <Nav.Link
                            href="/#contact"
                            className={
                                activeLink === "contact" ? "active navbar-link" : "navbar-link"
                            }
                            onClick={() => onUpdateActiveLink("contact")}
                        >
                            <CustomTypography variant={"h4"} sx={{
                                fontSize: {xs: '2rem'}
                            }}>
                                Contact
                            </CustomTypography>

                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};
