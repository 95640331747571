import toDoApp from '../../../Images/Projects/Categories/reactNative/toDoApp.webp';

const reactNativeArray = [
    {
        title: 'ToDo App',
        image: toDoApp,
        description: 'Stay organized and productive with this intuitive to-do app, designed for both Android and iOS, to help you manage tasks effortlessly on the go.',
        comingSoon: 'Cooming Soon',
    },
];

export default reactNativeArray;