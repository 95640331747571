import autopsy from '../../../Images/Projects/Categories/itSecurity/autopsy.webp';
import rsa from '../../../Images/Projects/Categories/itSecurity/rsa.webp';
import mitreAttack from '../../../Images/Projects/Categories/itSecurity/mitreAttack.webp';
import malwareAnalysis from '../../../Images/Projects/Categories/itSecurity/malwareAnalysis.webp';


const itSecurityArray = [
    {
        title: 'Digital Forensic with Autopsy',
        image: autopsy,
        description: 'Explore the world of digital forensics by analyzing a Windows image using Autopsy, uncovering hidden data and solving complex cyber investigations.',
        comingSoon: 'Cooming Soon',
    },
    {
        title: 'RSA Cryptanalysis',
        image: rsa,
        description: 'Explore the strengths and weaknesses of RSA cryptography in securing modern digital communications.',
        comingSoon: 'Cooming Soon',
    },
    {
        title: 'MITRE ATT&CK Cyber Analysis',
        image: mitreAttack,
        description: 'Analyze a cyberattack on VSA servers using the MITRE ATT&CK matrix to reveal key attacker tactics and techniques.',
        comingSoon: 'Cooming Soon',
    },
    {
        title: 'Malware Analysis',
        image: malwareAnalysis,
        description: 'Dive into the analysis of the infamous REvil malware, using advanced tools to uncover its behavior and tactics in real-world cyberattacks.',
        comingSoon: 'Cooming Soon',
    },
];

export default itSecurityArray;