import React from "react";
import {Row} from "react-bootstrap";
import {Parallax} from "react-parallax";
import {useImage} from "react-image";
import {motion, useAnimation} from "framer-motion";
import {useInView} from "react-intersection-observer";
import credIMG from "../Images/Credentials/credentialsWallpaper.jpg";
import "tailwindcss/tailwind.css";
import {Box} from '@mui/material';
import CustomTypography from "../Components/CustomTypography";

export const Credentials = () => {
    // Bild laden
    const {src} = useImage({srcList: [credIMG]});

    // Steuerung der Animationen initialisieren
    const controls = useAnimation();

    // Hook, um zu überprüfen, ob das Element im Sichtbereich ist
    const [ref, inView] = useInView({triggerOnce: false});

    // Effekt, um Animationen basierend auf dem Sichtbereich zu starten/stoppen
    React.useEffect(() => {
        if (inView) {
            controls.start("visible"); // Wenn das Element im Sichtbereich ist, starte die Animation
        } else {
            controls.start("hidden"); // Wenn das Element aus dem Sichtbereich ist, stoppe die Animation
        }
    }, [controls, inView]);

    // Variablen für die Animation der gesamten Liste
    const listVariants = {
        hidden: {
            opacity: 0,
            y: 40,
        },
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                delayChildren: 0.5, // Verzögerung zwischen dem Einblenden der Listenelemente
                staggerChildren: 0.5, // Zeitverzögerung zwischen den Animationen der Listenelemente
            },
        },
    };

    // Variablen für die Animation der einzelnen Listenelemente
    const itemVariants = {
        hidden: {
            opacity: 0,
            y: 40,
        },
        visible: {
            opacity: 1,
            y: 0,
        },
    };

    // Inhalt der Komponente
    const content = (
        <Row>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'start',
                    alignItems: 'start',
                    height: '100%',
                    width: '100%',
                    mt: 5,
                    mb: 2,
                    px: {xs: 8, sm: 8, md: 16, lg: 20},
                    overflow: 'hidden'
                }}
            >
                <Box component="ul" sx={{paddingLeft: '1.5rem', margin: 0}}>
                    {/* Titel mit animierten Eigenschaften */}
                    <motion.h1
                        className="mb-5 text-4xl sm:text-5xl md:text-6xl lg:text-8xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-pink-500 to-yellow-400"
                        initial={{opacity: 0, y: 40}} // Anfangszustand der Animation
                        animate={controls} // Animation wird durch die Steuerung kontrolliert
                        variants={listVariants} // Verwendet die listVariants für Animationseigenschaften
                    >
                        <CustomTypography variant="h1">
                            Credentials
                        </CustomTypography>

                    </motion.h1>

                    {/* Liste mit animierten Listenelementen */}
                    <motion.ul
                        className="text-3xl sm:text-3xl md:text-4xl lg:text-5xl"
                        ref={ref} // Referenz, um zu überprüfen, ob das Element im Sichtbereich ist
                        initial="hidden" // Anfangszustand der Animation
                        animate={controls} // Animation wird durch die Steuerung kontrolliert
                        variants={listVariants} // Verwendet die listVariants für Animationseigenschaften
                    >
                        {/* Einzelne Listenelemente mit Animationen */}
                        <motion.li variants={itemVariants}>
                            <CustomTypography variant="h3" component="li"
                                              sx={{paddingBottom: '5px', listStyleType: 'disc'}}>
                                Ethereum and Solidity: The Complete Developer’s Guide
                            </CustomTypography>

                        </motion.li>
                        <motion.li variants={itemVariants}>
                            <CustomTypography variant="h3" component="li"
                                              sx={{paddingBottom: '5px', listStyleType: 'disc'}}>
                                Introduction to Web Development with HTML5, CSS3, JavaScript
                            </CustomTypography>
                        </motion.li>
                        <motion.li variants={itemVariants}>
                            <CustomTypography variant="h3" component="li"
                                              sx={{paddingBottom: '5px', listStyleType: 'disc'}}>
                                Figma UI UX Design Essentials
                            </CustomTypography>
                        </motion.li>
                        <motion.li variants={itemVariants}>
                            <CustomTypography variant="h3" component="li"
                                              sx={{paddingBottom: '5px', listStyleType: 'disc'}}>
                                European Computer Driving License
                            </CustomTypography>
                        </motion.li>
                    </motion.ul>

                </Box>

            </Box>
        </Row>
    );

    // Rückgabe der gesamten Komponente
    return (
        <section
            className="Credentials h-screen relative overflow-hidden"
            id="credentials"
        >
            {/* Parallax-Hintergrund */}
            <Parallax
                className="w-full h-screen overflow-hidden"
                bgImage={src}
                strength={500} // Stärke des Parallax-Effekts
            >
                <div className="absolute top-0 left-0 w-full h-full">
                    {content} {/* Einbettung des Inhalts */}
                </div>
            </Parallax>
        </section>
    );
};
