import React from "react";
import {Box} from "@mui/material";
import CustomTypography from "../../Components/CustomTypography";
import ProjectClassesContainer from "./Components/ProjectClassesContainer";
import projectClasses from './Data/projectClasses';


export const Projects = () => {

    return (
        <Box component="section" id="project" sx={{paddingBottom: "100px"}}>
            <CustomTypography
                variant="h1"
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    paddingBottom: "50px",
                    paddingTop: "50px"
                }}
            >
                Projects
            </CustomTypography>
            <Box
                sx={{
                    padding: '20px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <ProjectClassesContainer objectArray={projectClasses}/>
            </Box>
        </Box>
    );
};
