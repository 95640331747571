import {Col, Container, Row} from "react-bootstrap";
import React, {useEffect, useState} from "react";
//https://www.npmjs.com/package/@ramonak/react-progress-bar
import ProgressBar from "@ramonak/react-progress-bar";
import {Parallax} from "react-parallax";
import {useImage} from "react-image";
import skillIMG from "../Images/Skills/Skills.webp";

export const Skills = () => {
    /*Lade das Bild vorher*/
    const {src} = useImage({srcList: [skillIMG]});

    /* State und Funktion zum Festlegen, ob der Bildschirm klein ist */
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 1200);

    /* Effect-Hook, um den Zustand zu aktualisieren, wenn sich die Fenstergröße ändert */
    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth < 1200);
        };

        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    /* Gemeinsamer Inhalt */
    const content = (
        <Row>
            <Col className="progressbarInRow">
                <p></p>

                <h3>German and Greek Language</h3>
                <ProgressBar
                    completed={100}
                    maxCompleted={100}
                    className="wrapper"
                    labelClassName="progressLabel"
                    // Or use dimgrey
                    // Or #C71585
                    bgColor="dimgrey"
                    labelAlignment="outside"
                ></ProgressBar>{" "}

                <h3>English Language</h3>
                <ProgressBar
                    completed={90}
                    maxCompleted={100}
                    className="wrapper"
                    labelClassName="progressLabel"
                    bgColor="dimgrey"
                    labelAlignment="outside"
                ></ProgressBar>{" "}

                <h3>Basic IT Security Knowledge</h3>
                <ProgressBar
                    completed={80}
                    maxCompleted={100}
                    className="wrapper"
                    labelClassName="progressLabel"
                    bgColor="dimgrey"
                    labelAlignment="outside"
                ></ProgressBar>{" "}

                <h3>Solidity</h3>
                <ProgressBar
                    completed={70}
                    maxCompleted={100}
                    className="wrapper"
                    labelClassName="progressLabel"
                    bgColor="dimgrey"
                    labelAlignment="outside"
                ></ProgressBar>{" "}

                <h3>Web Development Technologies: REACT, JavaScript, HTML and CSS</h3>
                <ProgressBar
                    completed={70}
                    maxCompleted={100}
                    className="wrapper"
                    labelClassName="progressLabel"
                    bgColor="dimgrey"
                    labelAlignment="outside"
                ></ProgressBar>{" "}
            </Col>
        </Row>
    );

    return (
        <section className="skill" id="skills">
            <div className="skillText">
                <h2>Skills</h2>
            </div>
            <Container className="skillContainer">
                <div className="skillBox">
                    {isSmallScreen ? (
                        /* Bild ohne Parallax für kleine Bildschirme */
                        <div
                            className="smallScreenBackgroundSKILLS"
                            style={{backgroundImage: `url(${src})`}}
                        >
                            {content}
                        </div>
                    ) : (
                        <Parallax
                            className="skillsParallax roundedParallax"
                            bgImage={src}
                            strength={500}
                        >
                            {" "}
                            <div>{content}</div>
                        </Parallax>
                    )}
                </div>
            </Container>
        </section>
    );
};
