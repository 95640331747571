import nextGenerationAccessControl from '../../../Images/Projects/Categories/solidity/nextGenerationAccessControl.webp';

const solidityArray = [
    {
        title: 'Next Generation Access Control',
        image: nextGenerationAccessControl,
        description: 'I built my portfolio website using React to showcase my projects and skills with a sleek, dynamic, and interactive design.',
        comingSoon: 'Cooming Soon!',
    },
];

export default solidityArray;