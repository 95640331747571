import ceasarCipher from '../../../Images/Projects/Categories/python/ceasarCipher.webp';
import passwordGenerator from '../../../Images/Projects/Categories/python/passwordGenerator.webp';
import calculator from '../../../Images/Projects/Categories/python/calculator.webp';
import hangMan from '../../../Images/Projects/Categories/python/hangMan.webp';
import higherLower from '../../../Images/Projects/Categories/python/higherLower.webp';
import guessMyNumber from '../../../Images/Projects/Categories/python/guessMyNumber.webp';
import blackJack from '../../../Images/Projects/Categories/python/blackJack.webp';

const pythonArray = [
    {
        title: 'Ceasar Cipher',
        image: ceasarCipher,
        description: 'This Python program lets you explore the classic Caesar Cipher by easily encoding or decoding messages with a simple letter shift.',
        comingSoon: 'Cooming Soon',
    },
    {
        title: 'Password Generator',
        image: passwordGenerator,
        description: 'This Python program quickly generates strong, customizable passwords to boost your online security with ease.',
        comingSoon: 'Cooming Soon',
    },
    {
        title: 'Calculator',
        image: calculator,
        description: 'This Python project offers a simple, user-friendly calculator for performing basic arithmetic, perfect for both learning Python and quick calculations.',
        comingSoon: 'Cooming Soon',
    },
    {
        title: 'Hangman Game',
        image: hangMan,
        description: 'Challenge your vocabulary and deduction skills in this classic Hangman game, where you must guess the word before the hangman is fully drawn!',
        comingSoon: 'Cooming Soon',
    },
    {
        title: 'Higher Lower Game',
        image: higherLower,
        description: 'Test your celebrity knowledge in this addictive game by guessing who has more social media followers—one wrong guess, and it’s game over!',
        comingSoon: 'Cooming Soon',
    },
    {
        title: 'Number Guessing Game',
        image: guessMyNumber,
        description: 'Test your luck and strategy in this addictive number guessing game, where you choose the difficulty and try to guess the correct number before running out of attempts!',
        comingSoon: 'Cooming Soon',
    },
    {
        title: 'BlackJack Game',
        image: blackJack,
        description: 'Experience the thrill of Blackjack, where strategy and luck collide as you aim to beat the dealer without exceeding 21!',
        comingSoon: 'Cooming Soon',
    },
];

export default pythonArray;