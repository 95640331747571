import linkedin from "../Icons/linkedin.svg";
import twitter from "../Icons/twitter.svg";
import xing from "../Icons/x.svg";
import github from "../Icons/github.svg";
import upwork from "../Icons/upwork.svg";
import {useImage} from "react-image";
import contactIMG from "../Images/Contact/contactWallpaper.webp";
import {Parallax} from "react-parallax";
import React from "react";
import "tailwindcss/tailwind.css";
import CustomTypography from "../Components/CustomTypography";

export const Contact = () => {
    /*Lade das Bild vorher*/
    const {src} = useImage({srcList: [contactIMG]});

    const content = (
        <div className="ml-5 mr-5 mt-1 max-w-2xl  p-3 xl:max-w-2xl lg:max-w-2xl md:max-w-xl sm:max-w-md mx-auto">
            <CustomTypography
                variant="h2"
                sx={{
                    textAlign: "center",
                    margin: "10px 0", // m-2 umgewandelt
                    padding: "10px", // p-2 umgewandelt
                    backgroundColor: "rgba(0, 0, 0, 0.5)", // bg-black bg-opacity-50
                    borderRadius: "8px", // abgerundete Ecken wie in Tailwind
                    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // drop-shadow-md in Tailwind
                }}
            >
                Get In Touch
            </CustomTypography>

            <CustomTypography
                variant="h4"
                sx={{
                    textAlign: "center",
                    margin: "10px 0", // m-2 umgewandelt
                    padding: "10px", // p-2 umgewandelt
                    backgroundColor: "rgba(0, 0, 0, 0.5)", // bg-black bg-opacity-50
                    borderRadius: "8px", // abgerundete Ecken wie in Tailwind
                    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // drop-shadow-md in Tailwind
                }}
            >
                E-Mail: stef@gvxds.com
            </CustomTypography>


            <div
                style={{
                    display: "flex",
                    justifyContent: "space-evently",
                    marginTop: 5,
                }}
            >
                <a
                    href="https://www.linkedin.com/in/stefanos-gavouchidis-626144209/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex-1 text-center p-1.5 m-1.5 bg-black bg-opacity-50 rounded-lg"
                >
                    <img src={linkedin} alt="" className="w-12 h-12 mx-auto shadow-md"/>
                </a>
                <a
                    href="https://www.get-in-it.de/profil/NpiBQsxog5FIYnGqQ5iyFtAjLkfOdwXj"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex-1 text-center p-1.5 m-1.5 bg-black bg-opacity-50 rounded-lg"
                >
                    <img src={xing} alt="" className="w-12 h-12 mx-auto shadow-md"/>
                </a>
                <a
                    href="https://github.com/StefGvxds"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex-1 text-center p-1.5 m-1.5 bg-black bg-opacity-50 rounded-lg"
                >
                    <img src={github} alt="" className="w-12 h-12 mx-auto shadow-md"/>
                </a>
                <a
                    href="https://twitter.com/steffgvxds"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex-1 text-center p-1.5 m-1.5 bg-black bg-opacity-50 rounded-lg"
                >
                    <img src={twitter} alt="" className="w-12 h-12 mx-auto shadow-md"/>
                </a>
                <a
                    href="https://www.upwork.com/freelancers/~01f74ae569759f576d?s=1110580755057594368"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex-1 text-center p-1.5 m-1.5 bg-black bg-opacity-50 rounded-lg"
                >
                    <img src={upwork} alt="" className="w-12 h-12 mx-auto shadow-md"/>
                </a>
            </div>
        </div>
    );

    return (
        <section id="contact" style={{height: "40vh"}}>
            <Parallax
                className="w-full overflow-hidden bg-cover bg-center mt-5"
                bgImage={src}
                strength={500}
                bgImageStyle={{
                    objectFit: "cover",
                    width: "100%",
                    height: "150vh",
                }}
                style={{
                    borderTop: "2px #F5F5F5 solid",
                    borderBottom: "2px #F5F5F5 solid",
                }}
            >
                {content}
            </Parallax>
        </section>
    );
};
