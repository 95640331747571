import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import CustomTypography from "./CustomTypography";

export const Footer = () => {
    return (
        <footer className="foota #121212 text-white text-center p-3">
            <Container>
                <Row>
                    <Col className="foEl" sm={12} md={3}>
                        <a href="/agb">
                            {/* AGB Allgemeine Geschäftsbedingungen */}
                            <CustomTypography variant="h6">
                                Terms and Conditions
                            </CustomTypography>

                        </a>
                    </Col>
                    <Col className="foEl" sm={12} md={3}>
                        <a href="/Privatepolicy" className="text-white">
                            {/* Datenschutzbestimmungen */}
                            <CustomTypography variant="h6">
                                Privacy Policy
                            </CustomTypography>
                        </a>
                    </Col>
                    <Col className="foEl" sm={12} md={3}>
                        <a href="/Legalnotice" className="text-white">
                            {/* Impressum */}
                            <CustomTypography variant="h6">
                                Legal Notice
                            </CustomTypography>
                        </a>
                    </Col>
                    <Col className="foEl" sm={12} md={3}>
                        <CustomTypography variant="h6">
                            © {new Date().getFullYear()}
                        </CustomTypography>
                    </Col>
                </Row>
            </Container>
        </footer>
    );
};
