import {Typography} from "@mui/material";
import {styled} from "@mui/system";

const getFontSize = (variant) => {
    switch (variant) {
        case "h1":
            return {
                xs: "2rem",
                sm: "3rem",
                md: "4rem",
                lg: "5rem",
                xl: "6rem",
            };
        case "h2":
            return {
                xs: "1.5rem",
                sm: "2rem",
                md: "2.5rem",
                lg: "3rem",
                xl: "3.5rem",
            };
        case "h3":
            return {
                xs: "1.25rem",
                sm: "1.75rem",
                md: "2rem",
                lg: "2.5rem",
                xl: "3rem",
            };
        case "h4":
            return {
                xs: "1rem",
                sm: "1.5rem",
                md: "1.75rem",
                lg: "2rem",
                xl: "2.5rem",
            };
        case "h5":
            return {
                xs: "0.875rem",
                sm: "1.25rem",
                md: "1.5rem",
                lg: "1.75rem",
                xl: "2rem",
            };
        case "h6":
            return {
                xs: "0.75rem",
                sm: "1rem",
                md: "1.25rem",
                lg: "1.5rem",
                xl: "1.75rem",
            };
        case "h7":  // Hinzufügen von h7
            return {
                xs: "0.625rem",
                sm: "0.75rem",
                md: "0.875rem",
                lg: "1rem",
                xl: "1.25rem",
            };
        case "h8":  // Hinzufügen von h8
            return {
                xs: "0.5rem",
                sm: "0.625rem",
                md: "0.75rem",
                lg: "0.875rem",
                xl: "1rem",
            };
        default:
            return undefined;
    }
};

const CustomTypography = styled((props) => <Typography {...props} />)(({theme, variant}) => {
    const fontSize = getFontSize(variant);
    return {
        fontFamily: "Centra, sans-serif",
        fontSize: fontSize?.xs,
        [theme.breakpoints.up('sm')]: {
            fontSize: fontSize?.sm,
        },
        [theme.breakpoints.up('md')]: {
            fontSize: fontSize?.md,
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: fontSize?.lg,
        },
        [theme.breakpoints.up('xl')]: {
            fontSize: fontSize?.xl,
        },
    };
});

export default CustomTypography;

//USE
// <CustomTypography
//      variant="h1"
//  >
//     Hi I'm Stefanos Gavouchidis
// </CustomTypography>

