import {Box, Grid} from "@mui/material";
import {useImage} from "react-image";
import headerIMG from "../Images/Banner/BannerPortrait.png";
import {useCallback, useEffect, useMemo, useState} from "react";
import CustomTypography from "../Components/CustomTypography";

export const Banner = () => {
    // Lade das Bild vorher
    const {src} = useImage({srcList: headerIMG});

    // Text schreiben und löschen für die Animation
    const [loopNum, setLoopNum] = useState(0);
    const [isDeleting, setIsDeleting] = useState(false);

    // Der Text, der animiert wird
    const toRotate = useMemo(() => ["CS Graduate", ""], []);
    const [text, setText] = useState("");
    const [delta, setDelta] = useState(300 - Math.random() * 100);
    const period = 2000; // 1000 = 1 Sekunde

    const tick = useCallback(() => {
        let i = loopNum % toRotate.length;
        let fullText = toRotate[i];
        let updatedText = isDeleting
            ? fullText.substring(0, text.length - 1)
            : fullText.substring(0, text.length + 1);

        setText(updatedText);

        if (isDeleting) {
            setDelta((prevDelta) => prevDelta / 2);
        }

        if (!isDeleting && updatedText === fullText) {
            setIsDeleting(true);
            setDelta(period);
        } else if (isDeleting && updatedText === "") {
            setIsDeleting(false);
            setLoopNum(loopNum + 1);
            setDelta(500);
        }
    }, [loopNum, toRotate, isDeleting, text, period]);

    useEffect(() => {
        let ticker = setInterval(() => {
            tick();
        }, delta);

        return () => {
            clearInterval(ticker);
        };
    }, [tick, delta]);

    return (
        <Box
            component="section"
            sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: {
                    xs: "center",
                    sm: "flex-start",
                    md: "center",
                },
                alignItems: "center",
                overflow: "hidden",
                minHeight: {
                    xs: "100vh",
                },
                height: {
                    md: "100vh",
                },
            }}
            id="home"
        >
            <CustomTypography
                variant="h1"
                sx={{
                    margin: 0,
                    paddingTop: {
                        xs: "0%",
                        sm: "3%",
                        md: "13%"
                    },
                    paddingBottom: {
                        xs: "20px",
                        md: "30px"
                    },
                    paddingLeft: {
                        xs: "5px",
                        md: "5px",
                    },
                    paddingRight: {
                        xs: "5px",
                        md: "5px",
                    },
                    fontWeight: "bold",
                    textAlign: "center",
                    justifyContent: "center",
                }}
            >
                Hi I'm Stefanos Gavouchidis, <span>{text}</span>
            </CustomTypography>

            <Grid
                container
                sx={{
                    alignItems: "center",
                    flexDirection: {
                        xs: "column",
                        md: "row",
                    },
                    justifyContent: "center",
                }}
            >
                <Grid
                    container
                    md={7}
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        order: {
                            xs: 2,
                            md: 1
                        },
                    }}
                >
                    {/*________________________________________________________________TEXT____________________________________________________________    */}
                    <Grid container sx={{
                        height: {
                            xs: "auto",
                            md: "100vh"
                        },
                        paddingTop: {
                            xs: "10px",
                            md: "0px"
                        },
                    }}>
                        <Grid item xs={0} md={1}></Grid>
                        <Grid
                            item
                            xs={12}
                            md={11}
                            sx={{
                                display: "flex",
                                flexDirection: {
                                    xs: "column",
                                    md: "row",
                                },
                                justifyContent: "center",
                                padding: {
                                    xs: "0 14px",
                                    md: "0",
                                },
                            }}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    textAlign: "center",
                                }}
                            >
                                <CustomTypography
                                    variant="h2"
                                    sx={{
                                        fontWeight: "bold",
                                        paddingBottom: "10px",
                                        color: "white",
                                    }}
                                >
                                    Web and Blockchain Developer
                                </CustomTypography>
                                {descriptionText}
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                {/*________________________________________________________________IMAGE____________________________________________________________    */}
                <Grid
                    item
                    xs={12}
                    md={5}
                    sx={{
                        height: {
                            xs: "auto",
                            md: "100vh"
                        },
                        flexDirection: {
                            xs: "column",
                            md: "row",
                        },
                        display: "flex",
                        alignItems: "flex-start",
                        order: {
                            xs: 1,
                            md: 2,
                        },
                    }}
                >
                    <Box
                        component="img"
                        src={src}
                        alt="HeaderImg"
                        sx={{
                            opacity: 0.85,
                            maxWidth: {
                                xs: "300px",
                                sm: "400px",
                                md: "600px",
                            },
                            height: "auto",
                            maxHeight: "100%",
                            objectFit: "contain",
                        }}
                    />
                </Grid>
            </Grid>
        </Box>
    );
};

const descriptionText = (
    <CustomTypography
        variant="h4"
        sx={{
            color: "white",
            textAlign: "center",
        }}
    >
        As a recent{" "}
        <span
            style={{
                textDecoration: "underline",
                background: "linear-gradient(to right, #f50057, #ffffff)",
                WebkitBackgroundClip: "text",
                backgroundClip: "text",
                color: "transparent",
            }}
        >
      Computer Science
    </span>{" "}
        graduate, I specialize in{" "}
        <span
            style={{
                textDecoration: "underline",
                background: "linear-gradient(to right, #f50057, #ffffff)",
                WebkitBackgroundClip: "text",
                backgroundClip: "text",
                color: "transparent",
            }}
        >
      React,
    </span>{" "}
        <span
            style={{
                textDecoration: "underline",
                background: "linear-gradient(to right, #f50057, #ffffff)",
                WebkitBackgroundClip: "text",
                backgroundClip: "text",
                color: "transparent",
            }}
        >
      JavaScript
    </span>{" "}
        and{" "}
        <span
            style={{
                textDecoration: "underline",
                background: "linear-gradient(to right, #f50057, #ffffff)",
                WebkitBackgroundClip: "text",
                backgroundClip: "text",
                color: "transparent",
            }}
        >
      Solidity
    </span>{" "}
        for web and{" "}
        <span
            style={{
                textDecoration: "underline",
                background: "linear-gradient(to right, #f50057, #ffffff)",
                WebkitBackgroundClip: "text",
                backgroundClip: "text",
                color: "transparent",
            }}
        >
      blockchain
    </span>{" "}
        development. Passionate about{" "}
        <span
            style={{
                textDecoration: "underline",
                background: "linear-gradient(to right, #f50057, #ffffff)",
                WebkitBackgroundClip: "text",
                backgroundClip: "text",
                color: "transparent",
            }}
        >
      UX/UI design
    </span>{" "}
        with{" "}
        <span
            style={{
                textDecoration: "underline",
                background: "linear-gradient(to right, #f50057, #ffffff)",
                WebkitBackgroundClip: "text",
                backgroundClip: "text",
                color: "transparent",
            }}
        >
      Figma
    </span>
        , I create engaging, user-centric experiences. Let's turn your ideas into
        reality together.
    </CustomTypography>
);