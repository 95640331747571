import React from "react";

const Legalnotice = () => (
  <section className="legaln">
    <div>
      <h1>Legal Notice</h1>
      <p>Website Owner: Stefanos Gavouchidis</p>
      <p>Email: Stef.gvxds@gmail.com</p>
      <p>Responsible for content: Stefanos Gavouchidis</p>
    </div>
  </section>
);

export default Legalnotice;