import jokerGameStats from '../../../Images/Projects/Categories/java/jokerGameStats.webp';

const javaArray = [
    {
        title: 'Joker Game Stats',
        image: jokerGameStats,
        description: 'This university project delivers a captivating statistical exploration of Greece\'s Tzoker lottery, offering a unique perspective on the game through data-driven insights.',
        comingSoon: 'Cooming Soon',
    },
];

export default javaArray;