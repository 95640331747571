import portfioWebsite from '../../../Images/Projects/Categories/react/portfioWebsite.png';

const reactArray = [
    {
        title: 'This Portfolio Website',
        image: portfioWebsite,
        description: 'I built my portfolio website using React to showcase my projects and skills with a sleek, dynamic, and interactive design.',
        comingSoon: 'Cooming Soon',
    },
];

export default reactArray;